const { ownKeys } = Reflect

const ALLOWED_TO_BE_OVERRIDDEN = ['toString', 'toLocaleString', 'valueOf']

const makePropertyReadOnly = (prototype: any, prop: string | symbol, descriptor: PropertyDescriptor) => {
	// Skip the constructor property
	if (prop === 'constructor') {
		return
	}

	if ('value' in descriptor && descriptor.configurable && !ALLOWED_TO_BE_OVERRIDDEN.includes(String(prop))) {
		const { value, enumerable, get, set } = descriptor

		const newDescriptor = {
			enumerable,
			configurable: false,
			...(value ? { value, writable: false } : { get, set }),
		}

		Object.defineProperty(prototype, prop, newDescriptor)
	}
}

/**
 * Makes all properties of an object read-only within a given context by locking their configurations
 * and preventing modifications. This provides a stronger level of immutability than Object.freeze()
 * by also protecting the object's prototype chain.
 *
 * @param key - The key under which the object exists in the context
 * @param context - The object containing the target object to be made read-only
 *
 * Key features:
 * - Makes all properties non-writable and non-configurable
 * - Protects prototype chain properties
 * - Preserves special methods (toString, toLocaleString, valueOf)
 * - Maintains property enumerability
 * - Handles both string and symbol property keys
 */
const makeObjectPropertiesReadOnly = (key: string, context: Record<string, any>) => {
	const protectedObj = context[key]
	if (!protectedObj) {
		return
	}

	let enumerable = false
	try {
		const descriptor = Object.getOwnPropertyDescriptor(context, key)
		if (descriptor) {
			enumerable = descriptor.enumerable || false
		}
	} catch (error) {
		console.warn(`Failed to get property descriptor for key "${key}":`, error)
	}

	// Define a strict property on the global object to protect the object
	// @ts-expect-error
	globalThis.defineStrictProperty(key, protectedObj, context, enumerable)

	const prototype = protectedObj?.prototype
	if (prototype) {
		const prototypeDescriptors = Object.getOwnPropertyDescriptors(prototype)

		// Lock down prototype properties
		Object.keys(prototypeDescriptors).forEach((prop) => {
			const descriptor = prototypeDescriptors[prop]

			if (descriptor) {
				makePropertyReadOnly(prototype, prop, descriptor)
			}
		})
	}

	// Go over all property keys (string and symbol)
	ownKeys(protectedObj).forEach((prop) => {
		const descriptor = Object.getOwnPropertyDescriptor(protectedObj, prop)
		if (descriptor && (descriptor.writable || descriptor.configurable)) {
			// Define getters and setters on the original object to prevent redefinition of properties
			// @ts-expect-error
			globalThis.defineStrictProperty(prop.toString(), protectedObj[prop], protectedObj, descriptor.enumerable)
		}
	})
}

export default makeObjectPropertiesReadOnly
